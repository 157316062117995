import * as React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import '../styles/styles.scss'

import LayoutRoot from '../components/LayoutRoot'
import LayoutMain from '../components/LayoutMain'
import { Language, persistLanguage, getLanguage } from '../state'

interface SiteMetadata {
  siteMetadata: {
    title: string
    description: string
  }
}

interface LanguageQuery {
  edges: {
    node: {
      lang: string
      dataString: string
    }
  }[]
}

type StaticQueryProps = {
  site: SiteMetadata
  languages: LanguageQuery
}

class Index extends React.Component<{ site: SiteMetadata }> {
  shouldComponentUpdate() {
    return false
  }

  render() {
    const { site, children } = this.props
    return (
      <>
        <Helmet
          title={site.siteMetadata.title}
          meta={[
            { name: 'description', content: site.siteMetadata.description },
            { name: 'keywords', content: 'UX Design, UI Design, Startup' },
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'
            },
            { name: 'mobile-web-app-capable', content: 'yes' },
            { name: 'apple-mobile-web-app-capable', content: 'yes' },
            { name: 'theme-color', content: '#0d3f69' },
            {
              name: 'google-site-verification',
              content: 'tyqIB9AYeYgUsJ6f8vuhpeV2X4O7izAY730nI7WogsI'
            },

            { property: 'og:url', content: 'http://7bits.cc/' },
            {
              property: 'og:title',
              content: '7bits | Especialista em UX/UI Design'
            },
            {
              property: 'og:description',
              content:
                'Na 7bits, nós criamos valor através do design. Fazemos entrevistas, protótipos, testes de usabilidade, sempre com o objetivo de criar a melhor experiência.'
            },
            { property: 'og:image', content: 'http://i.imgur.com/35iCAFV.png' }
          ]}
        >
          <script
            async
            src="//load.sumome.com/"
            data-sumo-site-id="585e2d724e999dc471f6f35510fc84db83d08e39c0e13d5a74159a8f7a27e892"
          />

          <script>{`
        var disqus_resolve;
        var disqus_ready = new Promise(resolve => {
          disqus_resolve = resolve;
        });
        var disqus_config = function () {
          disqus_resolve();
        };
        (function() { // DON'T EDIT BELOW THIS LINE
          var d = document, s = d.createElement('script');
          s.src = 'https://7bits.disqus.com/embed.js';
          s.setAttribute('data-timestamp', +new Date());
          (d.head || d.body).appendChild(s);
        })();
      `}</script>
        </Helmet>
        <LayoutMain>{children}</LayoutMain>
      </>
    )
  }
}

interface Dictionaries {
  [lang: string]: {
    [key: string]: string
  }
}

const IndexLayoutInitialState = () => ({
  language: {
    value: getLanguage(),
    loading: true
  }
})

class IndexProvider extends React.Component<
  StaticQueryProps,
  ReturnType<typeof IndexLayoutInitialState>
> {
  get dictionary() {
    return IndexProvider.dictionaries(this.props.languages)
  }
  static languageCode(code: string) {
    return code.split('-')[0]
  }

  static dictionaries(languages: LanguageQuery): Dictionaries {
    return languages.edges.reduce(
      (map, { node: { lang, dataString } }) =>
        Object.assign(map, {
          [IndexProvider.languageCode(lang)]: JSON.parse(dataString)
        }),
      {}
    )
  }

  state = IndexLayoutInitialState()

  public componentDidMount() {
    this.updateData()
  }

  public componentDidUpdate() {
    this.updateData()
  }

  public render() {
    return (
      <LayoutRoot>
        <Language.Provider
          value={{
            languages: Object.keys(this.dictionary),
            selected: this.state.language.value,
            setLanguage: this.setLanguage,
            translate: this.translate
          }}
        >
          <Index site={this.props.site}>{this.props.children}</Index>
        </Language.Provider>
      </LayoutRoot>
    )
  }

  private translate = (key: string): string => {
    const dictionaries = this.dictionary
    const language = this.state.language.value
    if (!dictionaries) return ''
    const current = dictionaries[language]
    if (!current) return ''
    return current[key] || key
  }

  private update(value: string) {
    this.setState({
      language: { value, loading: Boolean(this.dictionary[value]) }
    })
  }

  private setLanguage = (value: string) => {
    const language = IndexProvider.languageCode(value)
    persistLanguage(language)
    this.update(value)
  }

  private updateData() {
    const { value, loading } = this.state.language
    const next_loading = Boolean(this.dictionary[value])

    if (loading !== next_loading) {
      this.update(value)
    }
  }
}

class IndexLayout extends React.Component {
  render() {
    return (
      <StaticQuery
        render={data => (
          <IndexProvider site={data.site} languages={data.languages} {...this.props} />
        )}
        query={graphql`
          {
            site {
              siteMetadata {
                title
                description
              }
            }
            languages: allPrismicDictionary {
              edges {
                node {
                  lang
                  dataString
                }
              }
            }
          }
        `}
      />
    )
  }
}

export default IndexLayout
