import * as React from 'react'
import styled from 'react-emotion'

const StyledPage = styled.section`
  display: block;
  flex: 1;
  position: relative;
`

interface PageProps {
  className?: string
  id?: string
}

const Page: React.SFC<PageProps> = ({ children, className, id }) => (
  <StyledPage id={id} className={className}>
    {children}
  </StyledPage>
)

export default Page
